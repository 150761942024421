import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { MemberArea } from '@memberspot/shared/model/memberarea';

export interface MemberAreaState
  extends EntityState<MemberArea, string>,
    ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'memberareas' })
export class MemberAreaStore extends EntityStore<MemberAreaState> {
  constructor() {
    super();
  }
}
